<template>
  <div class="wrapper ">
    <div class="wrapper__container">
    <jublia-header />
    <div class="container container-transparent ">
      <div class="flex items-center justify-between mx-auto">
        <jublia-h1 class="w-1/2 text-jublia-bluest DINBold">{{
          $t('home.Photos.title')
        }}</jublia-h1>
        <div
          @click="triggerClick"
          class=" w-2/5 relative  flex justify-center items-center bg-jublia-bluest h-full text-jublia-white py-2 rounded-lg cursor-pointer"
          style="min-width:50%;"
        >
          <input
            id="files"
            @change="uploadImage"
            accept=".jpg, .png|image/*"
            style="visibility:hidden;"
            type="file"
            class="absolute none w-full"
            capture
          />
          <img
            src="@/assets/icons/add.svg"
            width="10"
            height="10"
            class="mr-2 mb-0"
          />
          <jublia-p class="uppercase">{{ $t('common.addPhoto') }}</jublia-p>
        </div>
      </div>
      <!-- - 6 mois -->
      <div v-if="getListImages('-6mois').length" class="mt-4">
        <jublia-h2 class="text-white">
          {{ $t('myEvolution.starting') }}
        </jublia-h2>
        <ul class="flex flex-wrap-reverse">
          <li
            v-for="i in getListImages('-6mois')"
            :key="i.Key"
            class="p-2 w-1/2 md:w-1/4 h-56 cursor-pointer relative ml-2 mb-4"

          >
            <div
              :style="{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url('${addressAWS}/${i.Key}')`
              }"
              @click="expandImage(`${addressAWS}/${i.Key}`)"
              class="w-full h-full"
               style='border:1px solid rgba(59, 130, 246, 0.5); border-radius: 10px;'
            >
              <span
               style="bottom: -22px"
                v-if="$i18n.getLocale() == 'fr'"
                class="absolute bottom--22 left-0 text-jublia-bluest text-sm mt-2 ml-3"
                >{{formatDate(i.LastModified,$i18n.getLocale()) }}</span
              >

              <span
                v-if="$i18n.getLocale() == 'en'"
                style="bottom: -22px"
                class="absolute bottom-0 left-0 text-jublia-bluest text-sm ml-3 mt-2"
                >{{  formatDate(i.LastModified,$i18n.getLocale())}}</span
              >
            </div>
            <icon-delete
              @click.native="deleteImage(i.Key)"
              class="absolute bottom--22 right-0 w-4 h-4 cursor-pointer text-white m-1 z-10 mt-2"
            />
          </li>
          <div
            v-if="isLoading"
            class="w-full bg-jublia-purple h-full flex justify-center items-center"
          >
            <jublia-loader />
          </div>
        </ul>
      </div>
      <!-- + 6 mois -->
      <div v-if="getListImages('+6mois').length" class="mt-4">
        <jublia-h2 class="text-white">
          {{ $t('myEvolution.more6month') }}
        </jublia-h2>
        <ul class="flex flex-wrap-reverse">
          <li
            v-for="i in getListImages('+6mois')"
            :key="i.Key"
            class="p-2 w-1/2 md:w-1/4 h-56 cursor-pointer relative ml-2 mb-4"
          >
            <div
              :style="{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url('${addressAWS}/${i.Key}')`
              }"
              @click="expandImage(`${addressAWS}/${i.Key}`)"
              class="w-full h-full"
            >
              <span
               style="bottom: -22px"
                v-if="$i18n.getLocale() == 'fr'"
                class="absolute bottom--22 left-0 text-jublia-bluest text-sm mt-2 ml-3"
                >{{formatDate(i.LastModified,$i18n.getLocale()) }}</span
              >

              <span
                v-if="$i18n.getLocale() == 'en'"
                style="bottom: -22px"
                class="absolute bottom-0 left-0 text-jublia-bluest text-sm ml-3 mt-2"
                >{{  formatDate(i.LastModified,$i18n.getLocale())}}</span
              >
            </div>
            <icon-delete
              @click.native="deleteImage(i.Key)"
              class="absolute bottom--22 right-0 w-4 h-4 cursor-pointer text-white m-1 z-10 mt-2"
            />
          </li>
          <div
            v-if="isLoading"
            class="w-full bg-jublia-purple h-full flex justify-center items-center"
          >
            <jublia-loader />
          </div>
        </ul>
      </div>
      <!-- + 12 mois -->
      <div v-if="getListImages('+12mois').length" class="mt-4">
        <jublia-h2 class="text-white">
          {{ $t('myEvolution.more12month') }}
        </jublia-h2>
        <ul class="flex flex-wrap-reverse">
          <li
            v-for="i in getListImages('+12mois')"
            :key="i.Key"
            class="p-2 w-1/2 md:w-1/4 h-56 cursor-pointer relative"
          >
            <div
              :style="{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url('${addressAWS}/${i.Key}')`
              }"
              @click="expandImage(`${addressAWS}/${i.Key}`)"
              class="w-full h-full"
            >
             <span
               style="bottom: -22px"
                v-if="$i18n.getLocale() == 'fr'"
                class="absolute bottom--22 left-0 text-jublia-bluest text-sm mt-2 ml-3"
                >{{formatDate(i.LastModified,$i18n.getLocale()) }}</span
              >

              <span
                v-if="$i18n.getLocale() == 'en'"
                style="bottom: -22px"
                class="absolute bottom-0 left-0 text-jublia-bluest text-sm ml-3 mt-2"
                >{{  formatDate(i.LastModified,$i18n.getLocale())}}</span
              >
            </div>
            <icon-delete
              @click.native="deleteImage(i.Key)"
              class="absolute bottom--22 right-0 w-4 h-4 cursor-pointer text-white m-1 z-10 mt-2"
            />
          </li>
          <div
            v-if="isLoading"
            class="w-full bg-jublia-purple h-full flex justify-center items-center"
          >
            <jublia-loader />
          </div>
        </ul>
      </div>
     <!-- <div class=" rounded-lg text-center  h-24 p-4 my-12">
         border border-white
      </div>-->
      <div
        v-if="openZoom"
        class="absolute w-full h-full bg-white top-0 left-0 flex justify-center items-center z-20"
      >
        <img :src="imgZoom" />
        <icon-close
          @click.native="openZoom = false"
          class="w-6 h-6 cursor-pointer absolute top-0 right-0 m-8 text-black"
        />
      </div>
       <div
          @click="skip()"
          v-html="$t('home.Photos.text')"
          class="block text-base underline cursor-pointer  text-center mt-14 text text2 lg:w-1/2 md:w-1/2 lg:mx-auto md:mx-auto"
        />
          <div class="relative flex justify-between w-full my-8 lg:mx-auto  sm:mx-auto mt-8" >
           <div class="w-full flex justify-between content-center items-center mx-auto justify-items-stretch" @click="toggleMenu">
           <jublia-button
               @click="toggleMenu"
            >
              <div class="text-xl  DINBold font-bold flex-grow">
                {{ $t('common.menu') }}
              </div>

            </jublia-button></div>
            <div v-if="v=='d'"  class="w-full DINBold flex justify-between items-center mx-4 "  @click="goToSurvey">
              <jublia-button


            >
              <div class="text-xl DINBold font-bold flex-grow uppercase">
                {{ $t('popup.next') }}
              </div>

            </jublia-button></div>
        </div>
       </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaH2 from '@/components/text/jublia-h2'
import JubliaP from '@/components/text/jublia-p'
import JubliaLoader from '@/components/loader/jublia-loader'
import JubliaHeader from '@/components/header/jublia-header'
import IconDelete from '@/components/icons/delete'
import IconClose from '@/components/icons/close'
import JubliaButton from '@/components/button/jublia-button'
import axios from 'axios';
import { useI18n } from "vue3-i18n";
import moment from 'moment'

export default {
  name: 'LibraryPhotos',
  components: {
   'jublia-h1': JubliaH1,
    'jublia-p':JubliaP,
    'jublia-h2':JubliaH2,
    'jublia-loader':JubliaLoader,
    'jublia-header':JubliaHeader,
    'icon-delete':IconDelete,
   'icon-close': IconClose,
    'jublia-button':JubliaButton,
  },
  setup (props) {
    const images=[]
const i18n = useI18n();





  return {
  }
},
created: function () {
    this.moment = moment;
  },
  data() {
    return {
    images: [],
    v: sessionStorage.getItem('vb')|| 'd',
    isLoading: false,
    openZoom: false,
    imgZoom: null,
    addressAWS: null,
    getLocale:String
  }},
   mounted() {
     //$route.params.id

          axios.get(`/user/${this.$route.params.userId}/list`)
            .then(response => {
                this.images = response.data;

            })
            .catch(error => {
                console.log(error);
            });
    },
  async asyncData({ app, params }) {

    const { data } = await axios.get(`/users/${params.userId}/list`);

    return {
      app,
      params,
      images: data
    }
  },
  beforeMount() {
    this.localeDate()
    this.addressAWS = 'http://s3.amazonaws.com/uploads.jublia.ca'
  },
  methods: {
    formatDate(dateString,l) {

             if (dateString) {
               if(l=='fr'){
               this.moment.locale('fr')
               return this.moment(String(dateString)).format('D MMMM YYYY')
               }
               if(l=='en'){

                this.moment.locale('en-ca')
               return this.moment(String(dateString)).format('D MMMM YYYY')
               }
             }
        },
  getListImages(periode) {
      const dateNow = this.moment()
      const returnList = []
      if (periode === '-6mois') {
        for (const image in this.images) {
          const dateImage = this.moment(this.images[image].LastModified)
          if (dateNow.diff(dateImage, 'month', true) < 6) {
            returnList.push(this.images[image])
          }
        }
      } else if (periode === '+6mois') {
        for (const image in this.images) {
          const dateImage = this.moment(this.images[image].LastModified)
          if (
            dateNow.diff(dateImage, 'month', true) > 6 &&
            dateNow.diff(dateImage, 'month', true) < 12
          ) {
            returnList.push(this.images[image], this.images[image].LastModified)
          }
        }
      } else if (periode === '+12mois') {
        for (const image in this.images) {
          const dateImage =this.moment(this.images[image].LastModified)
          if (dateNow.diff(dateImage, 'month', true) > 12) {
            returnList.push(this.images[image])
          }
        }
      }
      return returnList
    },
    getLocale(){
      return this.$i18n.getLocale()
    },
    localeDate() {
      if (this.$i18n.getLocale()=='fr') {
        this.moment().locale('fr')
      } else if (this.$i18n.getLocale()=='en') {
       this.moment().locale('en-ca')
      }
    },
    closeExpand() {
      this.openZoom = false
      this.imgZoom = null
    },
    expandImage(src) {
      this.openZoom = true
      this.imgZoom = src
    },
    triggerClick(e) {
      this.$el.querySelector('#files').click()
    },
    async uploadImage(evt) {
      this.isLoading = true
      const user = this.$route.params.userId
      if (!user) return
      const fd = new FormData()
      fd.append('image', evt.target.files[0], fd.name)

      try {
        await axios.post(`/user/${user}/images`, fd)
        this.isLoading = false
        this.reloadImages()
      } catch (e) {
        this.isLoading = false
      }
    },
    async reloadImages() {

      axios.get(`/user/${this.$route.params.userId}/list`)
            .then(response => {
                this.images = response.data;

            })
            .catch(error => {
                console.log(error);
            });

    },
    async deleteImage(imageKey) {
      this.isLoading = true
      try {
        await axios.post(`/user/delete/${imageKey}`)
        this.isLoading = false
        this.reloadImages()
      } catch (e) {
        this.isLoading = false
      }
    },

    goToSurvey() {
      this.$router.push(`/survey`)
    },
    reset() {
      this.$cookies.removeAll()
      this.state = {}
    },
    ...mapMutations('menu',['toggleMenu'])
  }
}
</script>
<style lang="postcss" scoped>
li{
  max-width: 47%;
}
.text2 {
  text-align:center !important;
  font-size: 17px !important;
  /* @media all and (min-width: 500px) and (max-width: 767px) {
    font-size: 14px !important;
  }*/
}

.container-transparent{
  @media all and (min-width: 768px) {
  /* @apply pl-14;*/
   /* margin: 0 auto;*/
  }
}
</style>
